<template>
      <el-config-provider :locale="zhCn">

            <el-skeleton :loading="loading" animated>
                  <template #template>
                        <div style="width: 100vw;">
                              <div style="
                                display: flex;
                                align-items: center;
                                justify-items: space-between;
                              ">
                                    <el-skeleton-item variant="text" style="margin-right: 16px;height: 100vh;" />
                                    <!-- <el-skeleton-item variant="text" style="width: 30%" /> -->
                              </div>
                        </div>
                  </template>
                  <template #default>
                        <nav>
                              <!-- <router-link to="/">Home</router-link> |
                        <router-link to="/about">About</router-link> -->
                              <NavigationHeader />
                        </nav>
                        <router-view :key="key" />
                        <nav>
                              <NavigationFloor />
                        </nav>
                  </template>
            </el-skeleton>
      </el-config-provider>
</template>

<script setup>
      import NavigationHeader from './views/NavigationHeader.vue';
      import NavigationFloor from './views/NavigationFloor.vue';
      import zhCn from 'element-plus/dist/locale/zh-cn.mjs'
      import { watch, onMounted, ref, computed } from 'vue';
      import { useRoute } from "vue-router";


      const route = useRoute();
      // const router = useRouter();
      // console.log('====================================');
      // console.log(router);
      // console.log('====================================');

      const loading = ref(true)

      const key = () => {
            return route.name !== undefined ? route.name + new Date() : route + new Date();
      }
      watch(() => route, (newVal) => {
            if (newVal.hash) {
                  setTimeout(() => {
                        var anchor = document.querySelector(newVal.hash);
                        anchor.scrollIntoView();
                  }, 300)
            }
      }, { immediate: true, deep: true })

      onMounted(() => {
            setTimeout(() => {
                  loading.value = false;
            }, 200)
      })

</script>

<style scoped>
      #app {
            /* font-family: Avenir, Helvetica, Arial, sans-serif; */
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: grayscale;
            text-align: center;
            color: #2c3e50;
      }
</style>