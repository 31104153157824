<template>
      <div class="NavigationHeader h-100 bg-white flex items-center  px-210">
            <!-- justify-center -->
            <div>
                  <img @click="linkRouter('uccnIndex')" class="w-188 h-46" :src="getImageUrl('index-dy.png')" alt="">
            </div>
            <div class="flex font-18 mx-180 header">
                  <a class="col cursor_pointer" :class="{'color-2c':item.pathName == route.name}" v-for="item in headerInfo.linkHeader" :key="item.id" @click="linkRouter(item.pathName)">{{item.title}}</a>
            </div>
            <!-- <div class="font-12 relative">
                  <input class="min-w-300 h-36 border rounded-50 border-color-d2 pl-20" type="text" placeholder="请输入关键词搜索">
                  <div class="w-32 h-32 bg-color-2c rounded-50 absolute right-2 top-2 flex items-center justify-center">
                        <img class="w-20 h-20" :src="getImageUrl('index-search.png')" alt="">
                  </div>
            </div> -->
      </div>
</template>

<script setup>
      import { defineComponent, onMounted, reactive } from 'vue';
      import { useRoute, useRouter } from "vue-router";
      import { getImageUrl } from "@/utils/tools.js";
      // import { useStore } from "vuex";


      const route = useRoute();
      const router = useRouter();

      // const store = useStore();

      const headerInfo = reactive({
            linkHeader: [{
                  id: 1,
                  title: "首页",
                  pathName: "uccnIndex",
            }, {
                  id: 2,
                  title: "产品中心",
                  pathName: "uccnProduct",
            }, {
                  id: 3,
                  title: "新闻资讯",
                  pathName: "uccnCard",
            }, {
                  id: 4,
                  title: "关于我们",
                  pathName: "uccnAboutUs",
            },],
      })

      const linkRouter = (url) => {
            router.push({
                  name: url,
                  params: {
                        htmlName: process.env.NODE_ENV === "development" ? '' : ".html"
                  }
            })
      }

      onMounted(() => {

      })

      defineComponent({
            name: 'NavigationHeader',
      });
</script>

<style scoped>
      .NavigationHeader {
            /* max-width: 1390px; */
            margin: 0 auto;
      }

      .header>a:not(:first-child) {
            /* margin-left: 96px; */
            margin-left: 172px;
      }

      /* @media screen and (max-width: 1536px) {
            .header>div:not(:first-child) {
                  margin-left: 70px;
            }
      } */

      input::placeholder {
            color: #B3B3B3;
      }
</style>