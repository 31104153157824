<template>
      <div class="Navigationfloor pt-93 px-210 bg-color-0c">
            <div class="flex justify-between">
                  <div class="w-1/2 flex justify-between px-37">
                        <ul class="font-22 text-white text-left">
                              <a :href="'/uccnAboutUs' + indexHtml">关于我们</a>
                              <li class="text-white font-20 text-opacity-60">
                                    <a :href="'/uccnAboutUs' + indexHtml + '#uccnaboutus_company_introduction'">公司介绍</a>
                              </li>
                              <li class="text-opacity-60 text-white font-20">
                                    <a :href="'/uccnAboutUs' + indexHtml + '#uccnaboutus_company_culture'">公司企业文化</a>
                              </li>
                              <li class="text-opacity-60 text-white font-20">
                                    <a :href="'/uccnAboutUs' + indexHtml + '#uccnaboutus_company_honor'">公司荣誉</a>
                              </li>
                        </ul>
                        <ul class="font-22 text-white text-left">
                              <a :href="'/uccnProduct' + indexHtml">产品中心</a>
                              <li class="text-white font-20 text-opacity-60">
                                    <a :href="'/uccnProduct' + indexHtml + '#uccnproduct_use_core'">应用中心</a>
                              </li>
                              <li class="text-opacity-60 text-white font-20">
                                    <a :href="'/uccnProduct' + indexHtml + '#uccnproduct_use_show'">应用展示</a>
                              </li>
                              <li class="text-opacity-60 text-white font-20">
                                    <a :href="'/uccnProduct' + indexHtml + '#uccnproduct_ditch_show'">渠道展示</a>
                              </li>
                        </ul>
                        <ul class="font-22 text-white text-left" style="opacity: 0;">
                              友情链接
                              <li class="text-white font-20 text-opacity-60">百度云</li>
                              <li class="text-opacity-60 text-white font-20">阿里云</li>
                              <li class="text-opacity-60 text-white font-20">京东</li>
                        </ul>
                  </div>
                  <div class="px-21 flex flex-col justify-between">
                        <div class="flex">
                              <img class="w-66 h-66" :src="getImageUrl('uccnIndex-phonetwo.png')" alt="">
                              <div class="flex flex-col justify-between text-left ml-38 font-20 font-20">
                                    <div class="text-white text-opacity-60">技术咨询：</div>
                                    <div class="text-white text-opacity-60">0755-85279665</div>
                              </div>
                        </div>
                        <div class="flex">
                              <img class="w-66 h-66" :src="getImageUrl('uccnIndex-dztwo.png')" alt="">
                              <div class="flex flex-col justify-between text-left ml-38 font-20 font-20">
                                    <div class="text-white text-opacity-60">公司地址：</div>
                                    <div class="text-white text-opacity-60">深圳市宝安区西乡街道劳动社区名优采购中心B座1区420</div>
                              </div>
                        </div>
                  </div>
            </div>
            <div class="mt-71 w-full border border-white border-solid opacity-60"></div>
            <div class="py-60 font-16 text-white text-opacity-60 text-center">深圳市度易科技有限公司 版权所有 2018-2023 <a href="https://beian.miit.gov.cn" target="_blank">粤ICP备18101344号</a></div>
      </div>
</template>

<script setup>
      import { defineComponent, onMounted } from 'vue';
      import { getImageUrl } from "@/utils/tools.js";

      const indexHtml = process.env.NODE_ENV === "development" ? '' : ".html"

      onMounted(() => {

      })

      defineComponent({
            name: 'NavigationFloor',
      });
</script>

<style scoped>
      .NavigationFloor {
            /* max-width: 1390px; */
            margin: 0 auto;
      }

      ul li {
            list-style: none;
      }

      ul>li:first-child {
            margin-top: 40px;
      }

      ul>li:not(:first-child) {
            margin-top: 35px;
      }
</style>