import { createApp } from 'vue'
import './style.css'
import App from './App.vue'
import router from './router';
// 引入pinia 实例
import pinia from './pinia/pinia.js'
import dayjs from "dayjs";


// import 'lib-flexible'
import "tailwindcss/tailwind.css";  // Vue3中引入tailwindcss
import "./assets/css/index.css";
import "./utils/rem";

const Vue = createApp(App)

Vue.config.globalProperties.$dayjs = dayjs;

Vue.use(pinia).use(router).mount('#app')
