import { createRouter, createWebHistory, createWebHashHistory } from 'vue-router'
const routerHistory = createWebHistory()
const routerHashHistory = createWebHashHistory();

const indexHtml = process.env.NODE_ENV === "development" ? '' : "htmlName"
const htmlName = process.env.NODE_ENV === "development" ? '' : ".html"

const routes = [
      {
            path: '/',
            redirect: `/uccnIndex${htmlName}`,
            meta: {
                  nav: 'uccnIndex',
                  navheadr: false,
                  title: '首页',
            },
      },
      {
            path: `/uccnIndex:${indexHtml}`,
            name: 'uccnIndex',
            component: () => import('@/views/uccnIndex.vue'),
            meta: {
                  nav: 'uccnIndex',
                  navheadr: false,
                  title: '首页',
            }
      },
      {
            path: `/uccnAboutUs:${indexHtml}`,
            name: 'uccnAboutUs',
            component: () => import('@/views/uccnAboutUs.vue'),
            meta: {
                  nav: 'uccnAboutUs',
                  navheadr: false,
                  title: '关于我们',
            }
      },
      {
            path: `/uccnProduct:${indexHtml}`,
            name: 'uccnProduct',
            component: () => import('@/views/uccnProduct.vue'),
            meta: {
                  nav: 'uccnProduct',
                  navheadr: false,
                  title: '产品中心',
            }
      },
      {
            path: `/uccnCard:${indexHtml}`,
            name: 'uccnCard',
            component: () => import('@/views/uccnCard.vue'),
            meta: {
                  nav: 'uccnCard',
                  navheadr: false,
                  title: '新闻资讯',
            }
      },
      {
            path: `/uccnCardDetail/:${indexHtml}`,
            name: 'uccnCardDetail',
            component: () => import('@/views/uccnCardDetail.vue'),
            meta: {
                  nav: 'uccnCardDetail',
                  navheadr: false,
                  title: '资讯详情',
            }
      }
];

const router = createRouter({
      history: routerHistory,
      routes,
});

router.beforeEach(async (to) => {
      if (to.meta.title) { // 判断是否有标题
            document.title = to.meta.title;
      }
});

export default router